import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

import { Typography, IconButton } from "@material-ui/core";
import { useRecoilState } from "recoil";

import MenuIcon from "@material-ui/icons/Menu";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import LightModeIcon from "@material-ui/icons/Brightness7";
import DarkModeIcon from "@material-ui/icons/Brightness4";

import { darkModeState, drawerOpenState } from "./App";

import useAuth from "../hooks/useAuth";
import { DRAWER_WIDTH } from "../utils/constants";

const drawerWidth = DRAWER_WIDTH;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },

    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  })
);

const Header = () => {
  const { logout } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(drawerOpenState);
  const [isDarkMode, setIsDarkMode] = useRecoilState(darkModeState);

  const classes = useStyles();

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };
  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: isDrawerOpen,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, isDrawerOpen && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Todos
          </Typography>
          <IconButton
            aria-label="toggle theme"
            edge="end"
            onClick={() => setIsDarkMode(!isDarkMode)}
            className={classes.menuButton}
            color="inherit"
          >
            {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
          <IconButton
            aria-label="logout"
            onClick={logout}
            edge="end"
            className={classes.menuButton}
            color="inherit"
          >
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
