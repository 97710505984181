import { useContext } from "react";
import { Button } from "@material-ui/core";

import { AuthContext } from "../context/UserContext";

const AuthForm = () => {
  const { login } = useContext(AuthContext);
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        onClick={async (event) => {
          event.preventDefault();
          await login();
        }}
        variant="contained"
        color="primary"
      >
        Login With Google
      </Button>
    </div>
  );
};

export default AuthForm;
