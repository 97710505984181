import { Draggable } from "react-beautiful-dnd";

import MaterialListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

import { Todo } from "../../context/ListContext";
import useLists from "../../hooks/useLists";
import { useState } from "react";
import { Theme, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => {
  return {
    editInputField: {
      flexGrow: 1,
    },
  };
});

const ListItem = ({ t, idx }: { t: Todo; idx: number }) => {
  const { currentList, deleteItemFromList, toggleItemComplete } = useLists();
  const [isEditMode, setIsEditMode] = useState(false);
  if (!currentList) throw new Error("unreachable");
  return (
    <Draggable key={t.id} draggableId={t.id} index={idx}>
      {(provided) => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          <MaterialListItem key={t.id} role={undefined} dense>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={t.completed}
                tabIndex={-1}
                onChange={() => {
                  toggleItemComplete(currentList.id, idx);
                }}
              />
            </ListItemIcon>
            {isEditMode ? (
              <EditTodoForm {...{ setIsEditMode, desc: t.desc, idx }} />
            ) : (
              <ListItemText
                id={t.id}
                primary={`${idx + 1}. ${t.desc}`}
                style={{
                  textDecoration: t.completed ? "line-through" : "none",
                }}
                onDoubleClick={(e) => {
                  setIsEditMode(true);
                }}
              />
            )}
            <ListItemIcon>
              <IconButton
                aria-label="delete todo"
                onClick={() => {
                  deleteItemFromList(currentList.id, t);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </ListItemIcon>
            <ListItemIcon
              {...provided.dragHandleProps}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <DragIndicatorIcon />
            </ListItemIcon>
          </MaterialListItem>
        </div>
      )}
    </Draggable>
  );
};

function EditTodoForm({
  setIsEditMode,
  desc,
  idx,
}: {
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  desc: string;
  idx: number;
}) {
  const [inputVal, setInputVal] = useState(desc);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const { updateItems, currentList } = useLists();
  const submitUpdate = async () => {
    if (inputVal === desc) return setIsEditMode(false);
    if (!currentList) throw new Error("unreachable");
    setIsLoading(true);
    const ct = currentList.data.todos;
    const newTodos = [
      ...ct.slice(0, idx),
      { ...ct[idx], desc: inputVal },
      ...ct.slice(idx + 1),
    ];
    await updateItems(currentList.id, newTodos);
    setIsEditMode(false);
  };
  return (
    <TextField
      value={inputVal}
      disabled={isLoading}
      className={classes.editInputField}
      variant="outlined"
      size="small"
      multiline
      autoFocus
      onBlur={async (e) => {
        e.preventDefault();
        await submitUpdate();
      }}
      onChange={(e) => {
        setInputVal(e.target.value);
      }}
      onKeyPress={async (e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          await submitUpdate();
        }
      }}
    />
  );
}

export default ListItem;
