import { createStyles, makeStyles, Theme } from "@material-ui/core";
import ListArena from "./ListArena/ListArena";
import Sidebar from "./Sidebar";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    "@global": {
      html: {
        backgroundColor: theme.palette.background.default,
      },
    },
    root: {
      backgroundColor: theme.palette.background.default,
      display: "flex",
    },
  });
});

const Dashbaord: React.FC = () => {
  const classes = useStyles();
  return (
    // <div style={{ display: "flex" }}>
    <div className={classes.root}>
      <Sidebar />
      <ListArena />
    </div>
  );
};

export default Dashbaord;
