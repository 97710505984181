import React, { createContext, useCallback, useEffect, useState } from "react";

import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  User,
  onAuthStateChanged,
} from "firebase/auth";

import firebaseApp from "../utils/firebase";

const auth = getAuth(firebaseApp);
const provider = new GoogleAuthProvider();

export const AuthContext = createContext<{
  loading: boolean;
  user: User | null;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}>({
  loading: true,
  user: null,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

const AuthProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(false);
      setUser(user);
      window.user = user
    });
    return unsubscribe;
  }, []);

  const login = useCallback(async () => {
    setLoading(true);
    await signInWithPopup(auth, provider);
  }, []);

  const logout = useCallback(async () => {
    setLoading(true);
    await signOut(auth);
  }, []);

  return (
    <AuthContext.Provider value={{ loading, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
