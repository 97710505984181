import { useContext } from "react";

import { makeStyles, Theme, createStyles } from "@material-ui/core";

import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import ViewListIcon from "@material-ui/icons/ViewList";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import { useRecoilState } from "recoil";

import { DRAWER_WIDTH } from "../utils/constants";
import { ListContext } from "../context/ListContext";
import { drawerOpenState } from "./App";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    drawer: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
    drawerPaper: {
      width: DRAWER_WIDTH,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "space-between",
    },
  });
});

const Sidebar = () => {
  const classes = useStyles();
  const {
    listOrder,
    listHash,
    showList,
    currentList,
    createList,
    updateListsOrder,
  } = useContext(ListContext);
  const [isDrawerOpen, setIsDrawerOpen] = useRecoilState(drawerOpenState);
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={isDrawerOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            const val = prompt("what to name this list?");
            if (val === null) return;
            const listname = val.trim();
            if (!listname) {
              alert("invalid name!");
              return;
            }
            createList(listname);
          }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <DragDropContext
        onDragEnd={({ source, destination }) => {
          const srcIdx = source.index;
          const destIdx = destination?.index || 0;
          const newListOrder = [...listOrder];
          const [currentListId] = newListOrder.splice(srcIdx, 1);
          newListOrder.splice(destIdx, 0, currentListId);
          updateListsOrder(newListOrder);
        }}
      >
        <Droppable droppableId="droppable-sidebar">
          {(provided) => (
            <div ref={provided.innerRef}>
              <List>
                {listOrder
                  .map((k) => ({ ...listHash[k], id: k }))
                  .map((l, idx) => (
                    <Draggable key={l.id} draggableId={l.id} index={idx}>
                      {(provided) => (
                        <div
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          <ListItem
                            button
                            dense
                            key={l.id}
                            selected={currentList?.id === l.id}
                            onClick={(e) => {
                              e.preventDefault();
                              showList(l.id);
                            }}
                          >
                            <ListItemIcon {...provided.dragHandleProps}>
                              <ViewListIcon />
                            </ListItemIcon>
                            <ListItemText primary={l.name} />
                          </ListItem>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </List>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Drawer>
  );
};
export default Sidebar;
