import {
  addDoc,
  arrayUnion,
  arrayRemove,
  collection,
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import React, { createContext, useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { db } from "../utils/firebase";

export type Todo = {
  id: string;
  completed: boolean;
  desc: string;
};

enum ListTypes {
  simple = "simple",
}

type List = {
  name: string;
  data: { todos: Todo[] };
  type: ListTypes;
  id: string;
};

type ListHash = {
  [k: string]: List;
};

export const ListContext = createContext<{
  listHash: ListHash;
  createList: (listname: string) => void;
  deleteList: (listId: string) => void;
  showList: (listId: string) => void;
  addItemToList: (listId: string, desc: string) => void;
  deleteItemFromList: (listId: string, todo: Todo) => void;
  updateItems: (listId: string, newList: Todo[]) => Promise<void>;
  toggleItemComplete: (listId: string, itemIdx: number) => void;
  updateListName: (listId: string, newName: string) => Promise<void>;
  updateListsOrder: (newListOrder: string[]) => Promise<void>;
  resetItemsCompletion: (listId: string) => Promise<void>;
  currentList: List | null;
  listOrder: string[];
}>({
  listHash: {},
  createList: () => {},
  deleteList: () => {},
  showList: () => {},
  addItemToList: () => {},
  deleteItemFromList: () => {},
  updateItems: () => Promise.resolve(),
  toggleItemComplete: () => {},
  updateListName: () => Promise.resolve(),
  updateListsOrder: () => Promise.resolve(),
  resetItemsCompletion: () => Promise.resolve(),
  currentList: null,
  listOrder: [],
});

const getListRef = (listId: string, userId: string) =>
  doc(db, "users", userId, "lists", listId);

const ListProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const [currentList, setCurrentList] = useState<List | null>(null);
  const [currentListId, setCurrentListId] = useState<string | null>(null);
  const [listOrder, setListOrder] = useState<string[]>([]);
  const [listHash, setListHash] = useState<ListHash>({});

  useEffect(() => {
    if (!user) return;
    console.log("running list use effect");
    const unsubscribe = onSnapshot(
      collection(db, "users", user.uid, "lists"),
      (c) => {
        console.log("running colction sub callback");
        const mapping = c.docs.reduce((a, b) => {
          return {
            ...a,
            [b.id]: b.data(),
          };
        }, {}) as ListHash;
        setListHash(mapping);
      }
    );
    return unsubscribe;
  }, [user, listOrder]);

  useEffect(() => {
    if (!user) return;
    if (!currentListId) return;
    const unsubscribe = onSnapshot(
      doc(db, "users", user.uid, "lists", currentListId),
      (d) => {
        const data = d.data();
        if (data) setCurrentList({ ...data, id: currentListId } as List);
      }
    );
    return unsubscribe;
  }, [currentListId, user]);

  useEffect(() => {
    if (!user) return;
    const unsub = onSnapshot(doc(db, "users", user.uid), (d) => {
      const data = d.data() as { listOrder: string[] };
      if (data.listOrder) {
        setListOrder(data.listOrder);
      }
    });
    return unsub;
  }, [user]);

  const createList = async (listname: string) => {
    if (!user) throw new Error("unreachable");
    try {
      /// should create function that does these two steps simultaneously
      // and lock down with rules to make sure they never fall out of sync
      const ref = await addDoc(collection(db, "users", user.uid, "lists"), {
        name: listname,
        type: "simple",
        data: {
          todos: [],
        },
      });
      setCurrentListId(ref.id);
      await updateDoc(doc(db, "users", user.uid), {
        listOrder: [ref.id, ...listOrder],
      });
      //
    } catch (e) {
      console.error("error!!!", e);
    }
  };

  const showList = async (listId: string) => {
    setCurrentListId(listId);
  };

  const deleteList = async (listId: string) => {
    if (!user) throw new Error("unreachable");
    if (currentList?.id === listId) setCurrentList(null);
    updateDoc(doc(db, "users", user.uid), {
      listOrder: listOrder.filter((k) => k !== listId),
    });
    const listRef = doc(db, "users", user.uid, "lists", listId);
    deleteDoc(listRef);
  };

  const addItemToList = (listId: string, desc: string) => {
    if (!user) throw new Error("unreachable");
    const itemToAdd: Todo = {
      id: `${Date.now()}`,
      desc,
      completed: false,
    };
    updateDoc(getListRef(listId, user.uid), {
      "data.todos": arrayUnion(itemToAdd),
    });
  };

  const deleteItemFromList = (listId: string, todo: Todo) => {
    if (!user) throw new Error("unreachable");
    updateDoc(getListRef(listId, user.uid), {
      "data.todos": arrayRemove(todo),
    });
  };

  const updateItems = async (
    listId: string,
    newList: Todo[]
  ): Promise<void> => {
    if (!user) throw new Error("unreachable");

    /// to avoid glitchy re-render
    if (!currentListId || !currentList) {
      console.warn(`no update made to list "${currentList?.name}"`);
      return;
    }
    setCurrentList({
      ...currentList,
      data: {
        ...currentList.data,
        todos: newList,
      },
      id: currentListId,
    });
    //

    await updateDoc(getListRef(listId, user.uid), {
      "data.todos": newList,
    });
  };

  const toggleItemComplete = (listId: string, itemIdx: number) => {
    if (!user) throw new Error("unreachable");
    const todosCopy = listHash[listId].data.todos.map((t) => ({ ...t }));
    const newTodos = [...todosCopy];
    const newCompletionStatus = !newTodos[itemIdx].completed;
    const newTodo = { ...newTodos[itemIdx], completed: newCompletionStatus };
    newTodos[itemIdx] = newTodo;

    const newList = { ...listHash[listId] };
    newList.data.todos = newTodos;

    setListHash({
      ...listHash,
      [listId]: newList,
    });

    updateDoc(getListRef(listId, user.uid), {
      "data.todos": newTodos,
    });
  };

  const resetItemsCompletion = (listId: string) => {
    if (!user) throw new Error("unreachable");
    const todosCopy = listHash[listId].data.todos.map((t) => ({ ...t }));
    const newTodos = [...todosCopy].map((t) => {
      return {
        ...t,
        completed: false,
      };
    });

    const newList = { ...listHash[listId] };
    newList.data.todos = newTodos;

    setListHash({
      ...listHash,
      [listId]: newList,
    });

    return updateDoc(getListRef(listId, user.uid), {
      "data.todos": newTodos,
    });
  };

  const updateListName = (listId: string, newName: string) => {
    if (!user) throw new Error("unreachable");
    return updateDoc(getListRef(listId, user.uid), {
      name: newName,
    });
  };

  const updateListsOrder = (newListOrder: string[]) => {
    if (!user) throw new Error("unreachable");

    /// to avoid glitchy re-render
    setListOrder(newListOrder);
    //

    return updateDoc(doc(db, "users", user.uid), {
      listOrder: newListOrder,
    });
  };

  return (
    <ListContext.Provider
      value={{
        listHash,
        createList,
        deleteList,
        showList,
        currentList,
        addItemToList,
        deleteItemFromList,
        updateItems,
        listOrder,
        toggleItemComplete,
        updateListName,
        updateListsOrder,
        resetItemsCompletion,
      }}
    >
      {children}
    </ListContext.Provider>
  );
};

export default ListProvider;
