import { initializeApp } from "firebase/app";
import { getFirestore, Firestore } from "firebase/firestore";
import * as ops from "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDee0Am2vvV3Q-cvFJeDYUxOttogmB0c1A",
  authDomain: "fb-list-app.firebaseapp.com",
  projectId: "fb-list-app",
  storageBucket: "fb-list-app.appspot.com",
  messagingSenderId: "146398841772",
  appId: "1:146398841772:web:2f06f803ab23238e70c809",
  measurementId: "G-S86FH77H5J",
};

const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp);

declare global {
  interface Window {
    db: Firestore;
    ops: any;
    user: any;
  }
}

window.db = db;
window.ops = ops;

export default firebaseApp;
