import React from "react";
import { Droppable, DragDropContext } from "react-beautiful-dnd";
import useLists from "../../hooks/useLists";

import {
  List as MaterialList,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";

import ListItem from "./ListItem";
import { LIST_WIDTH } from "../../utils/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: LIST_WIDTH,
      margin: "0 auto",
    },
  })
);

const List = () => {
  const { currentList, updateItems } = useLists();
  if (!currentList) throw new Error("unreachable");

  const classes = useStyles();
  return (
    <DragDropContext
      onDragEnd={({ source, destination }) => {
        const srcIdx = source.index;
        const destIdx = destination?.index || 0;
        const newTodos = [...currentList.data.todos];
        const [currentTodo] = newTodos.splice(srcIdx, 1);
        newTodos.splice(destIdx, 0, currentTodo);
        updateItems(currentList.id, newTodos);
      }}
    >
      <Droppable droppableId={`droppable-${currentList.id}`}>
        {(provided) => (
          <div ref={provided.innerRef}>
            <MaterialList className={classes.root}>
              {currentList.data.todos.map((t, idx) => (
                <ListItem key={t.id} t={t} idx={idx} />
              ))}
              {provided.placeholder}
            </MaterialList>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default List;
