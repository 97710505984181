import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import AuthProvider from "./context/UserContext";
import ListProvider from "./context/ListContext";
import CssBaseline from "@material-ui/core/CssBaseline";
import { RecoilRoot } from "recoil";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <ListProvider>
        <RecoilRoot>
          <CssBaseline />
          <App />
        </RecoilRoot>
      </ListProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
