import { useState, useRef, useEffect } from "react";
import {
  IconButton,
  Paper,
  InputBase,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import useLists from "../../hooks/useLists";

import SendIcon from "@material-ui/icons/Send";
import { LIST_WIDTH } from "../../utils/constants";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    formRoot: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      maxWidth: LIST_WIDTH,
      margin: "0 auto",
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
  });
});

const NewTodoForm = () => {
  const classes = useStyles();
  const { currentList, addItemToList } = useLists();
  const [inputText, setInputText] = useState("");
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    console.log({ inputRef });
    const focusInputEl = (e: KeyboardEvent) => {
      if (e.key === "e" && e.metaKey) {
        console.log("yes!!!", { inputRef });
        e.preventDefault();
        inputRef.current?.focus();
      }
    };

    document.addEventListener("keydown", focusInputEl);
    return () => document.removeEventListener("keydown", focusInputEl);
  }, [inputRef]);

  return (
    <Paper
      component="form"
      className={classes.formRoot}
      onSubmit={(e) => {
        e.preventDefault();
        if (!currentList) throw new Error("no list");
        addItemToList(currentList.id, inputText);
        setInputText("");
      }}
    >
      <InputBase
        className={classes.input}
        inputProps={{ "aria-label": "new todo" }}
        onChange={(e) => {
          setInputText(e.target.value);
        }}
        value={inputText}
        inputRef={inputRef}
        autoFocus
      />
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="submit new todo"
      >
        <SendIcon />
      </IconButton>
    </Paper>
  );
};
export default NewTodoForm;
