import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { atom, useRecoilState } from "recoil";

import AuthForm from "./AuthForm";
import Dashboard from "./Dashboard";
import Header from "./Header";
import Loading from "./Loading";

import ProtectedRoute from "./ProtectedRoute";

import useAuth from "../hooks/useAuth";
import { useEffect, useMemo } from "react";
import { createTheme, ThemeProvider, useMediaQuery } from "@material-ui/core";

export const drawerOpenState = atom({
  key: "drawerOpenState",
  default: true,
});

export const darkModeState = atom({
  key: "darkModeState",
  default: false,
});

function App() {
  const { loading, isAuthenticated } = useAuth();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [isDarkMode, setIsDarkMode] = useRecoilState(darkModeState);

  useEffect(() => {
    /// todo: grab dark mode from localstoarage
    setIsDarkMode(prefersDarkMode);
  }, [setIsDarkMode, prefersDarkMode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          // type: prefersDarkMode ? "dark" : "light",
          type: isDarkMode ? "dark" : "light",
          // type: "dark",
        },
      }),
    // [prefersDarkMode]
    [isDarkMode]
  );
  return (
    <ThemeProvider theme={theme}>
      <Router>
        {loading ? (
          <Loading />
        ) : (
          <Switch>
            <Route
              path="/"
              exact
              render={() =>
                isAuthenticated ? (
                  <Redirect to="/dashboard" />
                ) : (
                  <Redirect to="/login" />
                )
              }
            />
            <ProtectedRoute
              path="/dashboard"
              exact
              component={() => (
                <>
                  <Header />
                  <Dashboard />
                </>
              )}
              isAuthenticated={isAuthenticated}
              authenticationPath="/login"
            />
            <Route
              path="/login"
              render={() =>
                isAuthenticated ? <Redirect to="/dashboard" /> : <AuthForm />
              }
            />
          </Switch>
        )}
      </Router>
    </ThemeProvider>
  );
}

export default App;
