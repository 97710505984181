import {
  Typography,
  Button,
  makeStyles,
  Theme,
  createStyles,
  TextField,
} from "@material-ui/core";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import ResetIcon from "@material-ui/icons/RotateLeft";

import { useState } from "react";
import useLists from "../../hooks/useLists";
import { LIST_WIDTH } from "../../utils/constants";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      maxWidth: LIST_WIDTH,
      margin: "0 auto",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    listHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingBottom: theme.spacing(1),
      height: 65,
    },
    formRoot: {
      "& > *": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
    listTitleAndResetContainer: {
      display: "flex",
      gap: "1rem",
    },
  });
});

const ListHeader = () => {
  const { currentList, deleteList, resetItemsCompletion } = useLists();
  if (!currentList) throw new Error("unreachable");
  const [isEditMode, setIsEditMode] = useState(false);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.drawerHeader} />
      <div className={classes.listHeader}>
        {isEditMode ? (
          <EditTitleForm {...{ setIsEditMode }} />
        ) : (
          <div className={classes.listTitleAndResetContainer}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                resetItemsCompletion(currentList.id);
              }}
            >
              <ResetIcon />
            </Button>
            <Typography
              variant="h6"
              component="h6"
              onClick={() => {
                setIsEditMode(true);
              }}
            >
              {currentList.name}
            </Typography>
          </div>
        )}
        <Button
          variant="contained"
          color="secondary"
          onClick={(e) => {
            const shouldDelete = window.confirm(
              `are you sure you want to delete list ${currentList.name}?`
            );
            if (shouldDelete) deleteList(currentList.id);
          }}
        >
          <DeleteSweepIcon />
        </Button>
      </div>
    </div>
  );
};

function EditTitleForm({
  setIsEditMode,
}: {
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const classes = useStyles();
  const { currentList, updateListName } = useLists();
  if (!currentList) throw new Error("unreachable");
  const { name: currentTitle } = currentList;
  const [inputVal, setInputVal] = useState<string>(currentTitle);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <form
      className={classes.formRoot}
      noValidate
      autoComplete="off"
      onSubmit={async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await updateListName(currentList.id, inputVal);
        setIsEditMode(false);
      }}
    >
      <TextField
        id="standard-basic"
        label="List title"
        size="small"
        value={inputVal}
        onChange={(e) => {
          setInputVal(e.target.value);
        }}
        onBlur={() => {
          setIsEditMode(false);
        }}
        autoFocus
        disabled={isLoading}
      />
    </form>
  );
}

export default ListHeader;
