import clsx from "clsx";
import { useRecoilValue } from "recoil";
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import { DRAWER_WIDTH } from "../../utils/constants";

import useLists from "../../hooks/useLists";
import useAuth from "../../hooks/useAuth";

import List from "./List";

import NewTodoForm from "./NewTodoForm";
import ListHeader from "./ListHeader";

import { drawerOpenState } from "../App";

const drawerWidth = DRAWER_WIDTH;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  })
);

const ListArena = () => {
  const { currentList } = useLists();
  const isDrawerOpen = useRecoilValue(drawerOpenState);
  const { user } = useAuth();
  const classes = useStyles();
  if (!user) throw new Error("unreachable");
  if (currentList === null) return <NullListMessage />;
  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: isDrawerOpen,
      })}
    >
      {currentList.type === "simple" && (
        <>
          <ListHeader />
          <List />
          <NewTodoForm />
        </>
      )}
    </main>
  );
};

function NullListMessage() {
  const isDrawerOpen = useRecoilValue(drawerOpenState);
  const classes = useStyles();
  const { user } = useAuth();
  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: isDrawerOpen,
      })}
    >
      <div className={classes.drawerHeader} />
      <Typography>Choose a list, {user?.displayName}</Typography>
    </main>
  );
}

export default ListArena;
